* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'MyCasinoFont';
  src: url('casino.3d-marquee-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c10930;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.font-family-common {
  font-family: 'Titillium Web', sans-serif;
}

.text-align-center {
  text-align: center;
}

.anchor-white {
  color: #fff !important;
}

.display-flex {
  display: flex;
}

.justify-content {
  justify-content: space-between;
}

.flex-column-aicenter-jccenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background: #ffffff !important;
  border-color: #ffffff !important;
  color: #c10930 !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid red !important;
  padding: 0.625em 1em !important;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border-radius: 0.25em;
  color: #ffffff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  font-size: 0.9em;
  background: transparent !important;
  border: 1px solid #ffffff !important;
  width: 100px !important;
}

.my-swal-container {
  background-color: #f5f5f5;
}

.my-swal-popup {
  background-color: #8b0000;
  border-radius: 10px;
}

.my-swal-title {
  color: #fff;
  font-size: 1.275em;
}
.my-button-swal-confirm {
  font-size: 0.9em !important;
  width: 100px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  background: transparent !important;
  border: 1px solid #ffffff !important;
}
.my-button-swal-cancel {
  font-size: 0.9em !important;
  width: 100px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  font-size: 0.9em;
}

div:where(.swal2-icon) {
  border-color: #ffffff !important;
}

*::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

/* COMMON STYLE */

body {
  padding: 0px;
  margin: 0px !important;
}
.worli {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  overflow: hidden;
}
.worli img {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  width: 100%;
}
.top-col {
  width: 100%;
  float: left;
  background: #2c3e50;
  padding: 5px 15px;
}
.top-col h2 {
  font-size: 18px;
  margin: 0px;
  color: #fff;
  float: left;
}
.top-col p {
  float: right;
  color: #fff;
  margin: 0px;
}
.top-col p span {
  margin-right: 10px;
}
.worli img:hover {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}
.content-col {
  width: 100%;
  float: left;
}
.content-col-left {
  width: 100%;
  float: left;
}
.carousel-item {
  margin: 0;
}

.right-top {
  width: 100%;
  background-color: #2c3e50;
  padding: 5px 10px;
  float: left;
  border-radius: 5px;
  margin-bottom: 10px;
}
.right-top h5 {
  color: #fff;
  float: left;
  font-size: 16px;
  margin: 0px;
  line-height: 30px;
}
.content-col-right a {
  float: right;
  font-size: 14px;
  color: #fff;
  padding: 3px 10px;
}

.card-row {
  color: #fff;
  background-image: linear-gradient(to right, #08c, #2c3e50);
  width: 100%;
  float: left;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 3px;
  font-weight: bold;
}

.modal-body {
  text-align: center;
}
.modal-header h4 {
  font-size: 18px;
}
.modal-body p {
  font-size: 14px;
  margin-top: 20px;
}
.modal-body p span {
  color: #04d604;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.modal-body h5 {
  text-align: right;
  font-size: 16px;
  font-weight: 400;
}
.modal-body h5 span {
  font-weight: 700;
}

.market-col {
  width: 100%;
  margin-top: 0px;
  float: left;
}
.top-col h3 {
  font-size: 14px;
  margin: 0px;
  color: #fff;
  float: left;
  padding: 3px 0;
}
.fancy-table {
  width: 100%;
  float: left;
  margin-top: 0px;
}
.lay,
.lay-color {
  background-color: #f9c9d4 !important;
}
.back,
.back-color {
  background-color: #a7d8fd !important;
}

.suspn-bg {
  background-color: rgba(0, 0, 0, 0.6);
  color: #ff0000;
  font-weight: bold;
  width: 160px;
  height: 35px;
  position: absolute;
  left: -10px;
  top: -15px;
  line-height: 40px;
  z-index: 1;
}

.suspn-bg-4 {
  background-color: rgba(0, 0, 0, 0.6);
  color: #ff0000;
  font-weight: bold;
  width: 160px;
  height: 35px;
  position: absolute;
  left: -10px;
  top: -2px;
  line-height: 40px;
  z-index: 1;
}

.suspn-bg-2 {
  background-color: rgba(0, 0, 0, 0.6);
  color: #ff0000;
  font-weight: bold;
  width: 80px;
  height: 34px;
  position: absolute;
  left: -10px;
  top: -14px;
  line-height: 39px;
  z-index: 1;
}
.suspn-bg-3 {
  background-color: rgba(0, 0, 0, 0.6);
  color: #ff0000;
  font-weight: bold;
  width: 80px;
  height: 45px;
  position: absolute;
  left: -10px;
  top: -17px;
  line-height: 48px;
  z-index: 1;
}
.bet-sec {
  background: transparent;
  border: none;
  width: 100%;
  position: relative;
  top: 0px;
  bottom: 0px;
  text-align: center;
}
.span1 {
  display: block;
  font-size: 12px;
}
.odd {
  font-size: 15px;
  font-weight: 700;
}
.table-bordered td a {
  color: #000;
}
/* .table-bordered td{ background-color: rgba(0,0,0,.05);} */
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 1px;
}
.table td,
.table th {
  padding: 1px 8px;
}

.cstm-tbl-dgn table tr th,
.cstm-tbl-dgn tbody tr td {
  text-transform: capitalize;
  padding: 3px 2px !important;
}
.cstm-tbl-dgn tbody tr td {
  font-size: 12px;
  text-transform: capitalize;
}
.cstm-tbl-dgn .lay {
  background-color: #f9c9d4 !important;
}
.cstm-tbl-dgn .back {
  background-color: #a7d8fd !important;
}

/* scorecard css */

.score {
  font-size: 16px;
  font-weight: 600;
  color: #fff !important;
}

.scorecard {
  width: 100%;
  padding: 5px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  color: '#fff';
}

/* scorecard css */

/* livetv css */

.livetveneble {
  width: 100%;
  border: 0px;
  min-height: 217px;
}

.livetvdisable {
  width: 100%;
  border: 0px;
  min-height: 217px;
  display: none;
}

/* livetv css */

/* SideBar Right Css */

.content-col-right {
  width: 100%;
}
.top-col {
  width: 100%;
  background: #2c3e50;
  padding: 5px 0px;
  float: left;
}
.top-col h2 {
  float: left;
  margin: 0px;
  font-size: 20px;
  color: #fff;
}
.top-col p {
  float: right;
  margin: 0px;
  font-size: 14px;
  color: #fff;
}
.content-col-left h5 {
  margin: 0px;
  text-align: right;
  font-size: 12px;
  color: #17a2b8;
  padding: 5px 0;
  width: 100%;
  float: left;
}
.result-col3 {
  width: 100%;
  float: left;
  background: #2c3e50;
  padding: 5px 10px;
}
.result-col3 h4 {
  font-size: 16px;
  color: #fff;
  margin: 0px;
  float: left;
}
.result-col3 a {
  float: right;
  color: #fff;
}
.right-top {
  width: 100%;
  background-color: #2c3e50;
  padding: 5px 10px;
  float: left;
  border-radius: 5px;
  margin-bottom: 10px;
}
.right-top h5 {
  color: #fff;
  float: left;
  font-size: 16px;
  margin: 0px;
  line-height: 30px;
}
.content-col-right a {
  float: right;
  font-size: 14px;
  color: #fff;
  padding: 3px 10px;
}
.my-beat {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: 1px #ccc solid;
  margin-top: 0px;
}
.my-beat-top {
  width: 100%;
  float: left;
  background-color: #2c3e50;
}
.my-beat-top h3 {
  font-size: 16px;
  color: #fff;
  margin: 0px;
  padding: 5px 10px;
}
.my-beat-content {
  width: 100%;
  float: left;
  padding: 0px 10px;
}
.my-beat-bottom {
  width: 100%;
  float: left;
  background: #eeeeee;
}
.my-beat-bottom .table {
  margin-bottom: 0px;
  border-bottom: 0px;
}
.my-beat-bottom .table td,
.table th {
  padding: 3px 10px !important;
}
.lucky-col {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  float: left;
  margin-bottom: 15px;
  text-align: center;
}
.lucky-col p {
  font-size: 14px;
  margin: 0px;
}
.on-bet-btn {
  width: 16%;
}
.lucky-col2 {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .content-col-right {
    width: 100%;
  }
}

/* SideBAr Right Css */

.cstm-chkbx {
  position: relative;
  cursor: pointer;
}
.cstm-chkbx label input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}
/* .cstm-chkbx label :checked ~ .icon-box {
    box-shadow: inset -2px -2px 5px white, inset 3px 3px 5px rgba(0, 0, 0, 0.1);
  } */
.cstm-chkbx label :checked ~ .icon-box .fa {
  transform: scale(0.95);
}
.cstm-chkbx label .icon-box {
  background: #ffc107;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.cstm-chkbx label .icon-box .fa {
  /* font-size: 30px;
    color: #6a9bd8; */
  font-size: 16px;
  color: #000;
}

.scorecard {
  width: 100%;
  padding: 3px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  overflow: hidden;
}
.scorecard:before {
  content: '';
  /* background-color: rgba(0, 0, 0, 0.6); */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.m_scorecard {
  width: 100%;
  padding: 5px;
  background-image: url(./assets/images/score_bg.webp);
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  background-position: center;
  height: 150px;
}
.m_scorecard:before {
  content: '';
  /* background-color: rgba(0, 0, 0, 0.6); */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.score_list {
  width: 100%;
}
.m_scorecard p {
  margin: 0px;
  color: #fff;
  font-size: 18px;
}
.score_list ul {
  padding: 0px;
  margin: 0px;
}
.score_list ul li {
  display: inline-block;
  /* width: 15px;
    height: 15px;
    background: #1b87d5;
    font-size: 10px;
    margin: 0px 2px;
    text-align: center;
    border-radius: 50%;
    line-height: 17px; */
}
.team-name {
  /* display: block;
  width: 100%; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  font-size: 16px;
  font-weight: 600;
  color: #fff !important;
}
.fancy-table .team-name,
.bookmaker-market .team-name {
  color: #000;
  background: #edeff0;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #aaa !important;
}
#LocalPlayer {
  font-size: 16px;
}
#VisitorPlayer {
  font-size: 16px;
}
.ball-runs {
  background: #0088cc;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  line-height: 18px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-right: 3px !important;
  font-size: 16px;
}
.ball-runs.four {
  background: #087f23;
}

.ball-runs.six {
  background: #883997;
}

.ball-runs.wicket {
  background: #b31515;
}
.ball-runs.wide {
  background: #c0d314;
}
.team-1 span {
  margin-right: 5px !important;
}
.team-1 {
  float: left !important;
  font-size: 10px !important;
}
.game-nav-bar {
  border-radius: 0;
  border-bottom: 0;
  box-shadow: 0px -5px 5px -5px rgb(0 0 0 / 50%);
  background: #2c3e50;
  border: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  overflow: hidden;
}
.game-nav-bar.nav-tabs .nav-item {
  text-align: center;
  padding: 7px 0px;
}
.game-nav-bar.nav-tabs .nav-item:nth-child(3) {
  text-align: right;
  width: 48%;
}
.game-nav-bar.nav-tabs .nav-item:nth-child(1) {
  width: 15%;
}
.game-nav-bar.nav-tabs .nav-item:nth-child(2) {
  text-align: left;
  padding-left: 10px;
  width: 34%;
}
.game-nav-bar.nav-tabs .nav-link {
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  border: none;
  color: #fff;
  border-right: 1px solid #fff;
  border-radius: 0px;
  padding: 3px 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.game-nav-bar.nav-tabs .nav-item:nth-child(2) .nav-link {
  border-right: none;
}
.game-nav-bar.nav-tabs .nav-item:last-child .nav-link {
  border-right: none;
  font-size: 16px;
}
.game-nav-bar.nav-tabs .nav-link.active {
  position: relative;
}
.game-nav-bar.nav-tabs .nav-link.active:after {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  top: -7px;
  content: '';
  left: 0;
}
.nav-link.active {
  background: transparent !important;
}
.dropdown-item.active {
  background: transparent;
}
@media (min-width: 999px) and (max-width: 1199px) {
  /* .body-content {
    padding: 145px 5px 60px 195px;
} */
}
@media only screen and (min-width: 768px) and (max-width: 998px) {
  .content-col-left {
    width: 100%;
  }
  .content-col-right {
    width: 100%;
  }
}

@media only screen and (min-width: 767px) {
  .bottom-tab .nav-tabs .nav-item {
    width: 11%;
  }
  .bottom-tab .nav-tabs .nav-item .nav-link {
    width: 100%;
    text-align: center;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(9) {
    width: 12%;
  }
}
@media only screen and (max-width: 767px) {
  .cstm-chkbx label .icon-box {
    background: transparent;
    padding: 0px;
  }
  .cstm-chkbx label .icon-box .fa {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }
  .market-col {
    margin-top: 0px;
  }
  .fancy-table {
    margin-top: 0px;
  }
  .table thead th,
  .odd {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .odd {
    font-size: 16px;
  }
  .market-title {
    padding: 5px 6px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #4b6580;
  }
  .market-title i {
    font-size: 14px;
  }
  .th_mrkt_ttl {
    color: #fff;
    font-size: 12px;
    font-weight: 400 !important;
  }
  .table-header > div {
    font-size: 12px;
    font-weight: 600 !important;
    text-transform: uppercase;
    color: #000 !important;
  }

  .table-row > div.back,
  .table-row > div.back1,
  .table-row > div.back2,
  .table-row > div.lay,
  .table-row > div.lay1,
  .table-row > div.lay2 {
    cursor: pointer;
  }
  .res-d-none {
    display: none;
  }
}

.dot {
  color: rgba(204, 204, 204, 0.349);
}

.team_result {
  width: 100%;
  display: inline-block;
}
.team_result ul {
  padding: 0px;
  margin: 0px;
}
.team_result ul li {
  padding: 0px;
  margin: 0px;
  /* width: 33.3333%; */
  float: left;
  list-style: none;
}
.team_result ul li:nth-child(1) {
  width: 40%;
}
.team_result ul li:nth-child(2) {
  width: 40%;
  text-align: right;
}
.team_result ul li:nth-child(3) {
  width: 20%;
}
.inply-team {
  font-weight: 600;
  font-size: 14px;
  /* color: #af683b; */
  color: #6ab4ff;
  width: 100%;
  height: 50px;
}
.inply-team span {
  display: inline-block;
  width: 100%;
  padding-top: 14px;
  font-size: 23px;
  /* color: #ff934d; */
  color: #0b85ff;
}
.lve-report {
  width: 100%;
  text-align: right;
}
.lve-report img {
  width: 35px;
}
.run-rate {
  width: 100%;
  border-top: 1px solid #686868;
  border-bottom: 1px solid #686868;
  display: flex;
  padding: 3px 0px;
  margin: 3px 0px;
  align-items: center;
}
.run-rate .team-name {
  font-size: 16px;
}
.run-lft {
  width: 45%;
  float: left;
}
.run-rgt {
  width: 65%;
  float: left;
}
.match_rport table {
  margin-bottom: 0px;
}
.match_rport table thead tr th {
  border: none;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  padding: 0px 0px 7px !important;
  /* color: #dd5f26; */
  color: #43a9dd;
}
.match_rport table tbody tr td {
  border: none;
  padding: 0px 0px 10px;
  color: #fff;
}
.match_rport table tbody tr td .plyr-nam,
.match_rport table tbody tr td .in_bat,
.match_rport table thead tr th .plyr-nam {
  font-weight: 600 !important;
  color: #30b7f7;
}
.shw-mtch-result {
  /* position: absolute; */
  background: #3467a5b8;
  display: inline-block;
  width: 100%;
  padding: 3px !important;
  color: #f7f7f7;
  font-size: 12px;
  text-align: center;
}

.mb_sb_btn {
  color: #fff;
  padding: 5px;
  border-radius: 0px;
  width: 100%;
  background: #38241b;
  border: 0px;
  font-weight: 400;
  font-size: 14px;
}
.bottom-tab {
  width: 100%;
  float: left;
}
.bottom-tab .nav-tabs .nav-item {
  margin-bottom: 0px;
}

.bottom-tab .nav-tabs .nav-link {
  border: 1px solid #ffffff;
  color: #fff;
  background: #0088cc;
  border-radius: 0px;
  padding: 10px 11px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
}
.bottom-tab .nav-link.active {
  background: #2c3e50 !important;
}

.bottom-tab .nav-link.active {
  background: #2c3e50 !important;
}
.bottom-tab .nav-tabs .nav-item:nth-child(5) .nav-link {
  padding: 10px 15px;
}
.bottom-tab .nav-tabs .nav-item:nth-child(6) .nav-link {
  padding: 10px 20px;
}
.bottom-tab .nav-tabs .nav-item:nth-child(7) .nav-link {
  padding: 10px 20px;
}
.bottom-tab .nav-tabs .nav-item:nth-child(8) .nav-link {
  padding: 10px 25px;
}
.bottom-tab .nav-tabs .nav-item:nth-child(9) .nav-link {
  padding: 10px 16px;
}
@media only screen and (min-width: 361px) and (max-width: 475px) {
  .bottom-tab .nav-tabs .nav-link {
    padding: 10px 15px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(9) .nav-link {
    padding: 10px 29px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(5) .nav-link {
    padding: 10px 18px;
  }
}

iframe body {
  margin: 0px !important;
}
iframe body > iframe body {
  margin: 0px !important;
}
.tab-content {
  padding: 0px;
}

@media only screen and (min-width: 401px) and (max-width: 475px) {
  .bottom-tab .nav-tabs .nav-link {
    padding: 10px 19px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(5) .nav-link {
    padding: 10px 21px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(6) .nav-link {
    padding: 10px 24px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(7) .nav-link {
    padding: 10px 25px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(8) .nav-link {
    padding: 10px 30px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(9) .nav-link {
    padding: 10px 34px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 400px) {
  .bottom-tab .nav-tabs .nav-link {
    padding: 10px 15px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(5) .nav-link {
    padding: 10px 22px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(6) .nav-link {
    padding: 10px 20px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(7) .nav-link {
    padding: 10px 25px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(8) .nav-link {
    padding: 10px 30px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(9) .nav-link {
    padding: 10px 23px;
  }
}
@media only screen and (max-width: 375px) {
  .bottom-tab .nav-tabs .nav-link {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 360px) {
  .bottom-tab .nav-tabs .nav-link {
    padding: 10px 13px;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(5) .nav-link {
    padding: 10px 18px !important;
  }
  .bottom-tab .nav-tabs .nav-item:nth-child(9) .nav-link {
    padding: 10px 21px !important;
  }
}

#custom-modal-5 .table thead {
  background-color: #2c3e50;
  color: #fff;
}

/* .modal-title{
  line-height: 1.2;
} */
.min-max {
  font-size: 12px;
  text-transform: capitalize;
}

.match-title {
  background-color: #2c3e50;
  padding: 4px 8px;
  color: #fff;
  font-size: 14px;
  display: table;
  width: 100%;
}

.match-name {
  font-weight: bold;
}

.container {
  display: grid;
  place-items: center;
  height: 100%;
  color: #fff;
}
